import { Manager } from '@twilio/flex-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import * as Flex from '@twilio/flex-ui';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const mountNode = document.getElementById('root');
const nodeOrSelector: Element | string = mountNode || 'body';

window.onload = () => {
  const configuration = {};

  Flex.progress(nodeOrSelector)
    .provideLoginInfo(configuration, nodeOrSelector)
    .then(() => Flex.Manager.create(configuration))
    .then(manager => renderApp(manager))
    .catch(error => handleError(error));
};

const renderApp = (manager: Manager) => {
  ReactDOM.render(<App manager={manager} />, mountNode);
};

function handleError(error: Error) {
  Flex.errorPage(error, nodeOrSelector);
  console.error('Failed to initialize Flex', error);
}

registerServiceWorker();
