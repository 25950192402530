import { ContextProvider, RootContainer, Manager } from '@twilio/flex-ui';
import React from 'react';

class App extends React.Component<{ manager: Manager }> {
  render() {
    const { manager } = this.props;

    if (!manager) {
      return null;
    }

    return (
      <ContextProvider manager={manager}>
        <RootContainer />
      </ContextProvider>
    );
  }
}

export default App;
